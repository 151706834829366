<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.mail") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-2" v-if="items.length > 0">
            <div v-for="(item, i) in items" :key="i" class="list-card">
              <div class="d-flex align-center px-2">
                <div style="word-break: break-all">
                  <div class="text-h6 text-brd-md white--text">
                    {{ item.Mail.title }}
                  </div>
                  <div class="brown--text">
                    {{ item.Mail.description }}
                  </div>
                  <div
                    class="brown--text text--lighten-2 mt-2"
                    v-if="
                      $moment(item.Mail.timestampExpired).diff($moment()) >= 0
                    "
                  >
                    {{ $t("string.expires_in") }}
                    {{$moment(item.Mail.timestampExpired).locale($_getLocaleForMoment()).fromNow()}}
                  </div>
                  <div class="brown--text text--lighten-2 mt-2" v-else>
                    {{ $t("string.expired") }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-center ms-3 me-2">
                <v-btn
                  x-large
                  color="yellow"
                  :disabled="
                    $moment(item.Mail.timestampExpired).diff($moment()) < 0
                  "
                  v-if="!item.Mail.timestampClaimed"
                  @click="
                    () => {
                      $router.push({
                        name: 'PageExploreMailDetail',
                        params: { id: item.Mail.id },
                      });
                    }
                  "
                >
                  {{ $t("string.open") }}
                </v-btn>
                <div v-else class="text-h2 success--text">
                  ✓
                </div>
              </div>
            </div>
          </div>
          <div class="pa-2" v-else>
            <div class="list-card">
              <div class="d-flex align-center px-2">
                <div style="word-break: break-all">
                  <div class="text-h6 text-brd-md white--text">
                    {{$t("string.empty")}}
                  </div>
                  <div class="brown--text">
                    {{$t("string.empty_msg")}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    items: [],
  }),
  created() {
    this.api.url =
      this.$api.servers.game + "/api/v1/" + this.$_getLocale() + "/game/mail";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp.Mail;
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
  },
};
</script>

    <style scoped>
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #fffbb8; */
}

.main-body {
  /* background-color: #fffbb8; */
  background-color: rgba(255, 244, 39, 0.3);
}

.list-card {
  padding: 10px 10px 10px 20px;
  border: #d8c0a0 3px solid;
  border-radius: 5px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3) !important;
  justify-content: space-between;
  margin-bottom: 20px;
}

.coin-box {
  line-height: 12px;
  background-color: #fff;
  border: #292218 solid 2px;
  margin: 0 3px;
  font-size: x-small;
  text-align: center;
}

.coin-img {
  background-color: #6b583c;
}

.text-brd-md {
  text-shadow: #b89469 3px 0px 0px, #b89469 2.83487px 0.981584px 0px,
    #b89469 2.35766px 1.85511px 0px, #b89469 1.62091px 2.52441px 0px,
    #b89469 0.705713px 2.91581px 0px, #b89469 -0.287171px 2.98622px 0px,
    #b89469 -1.24844px 2.72789px 0px, #b89469 -2.07227px 2.16926px 0px,
    #b89469 -2.66798px 1.37182px 0px, #b89469 -2.96998px 0.42336px 0px,
    #b89469 -2.94502px -0.571704px 0px, #b89469 -2.59586px -1.50383px 0px,
    #b89469 -1.96093px -2.27041px 0px, #b89469 -1.11013px -2.78704px 0px,
    #b89469 -0.137119px -2.99686px 0px, #b89469 0.850987px -2.87677px 0px,
    #b89469 1.74541px -2.43999px 0px, #b89469 2.44769px -1.73459px 0px,
    #b89469 2.88051px -0.838247px 0px;
}

.text-brd-sm {
  text-shadow: #8b5629 1px 0px 0px, #8b5629 0.540302px 0.841471px 0px,
    #8b5629 -0.416147px 0.909297px 0px, #8b5629 -0.989992px 0.14112px 0px,
    #8b5629 -0.653644px -0.756802px 0px, #8b5629 0.283662px -0.958924px 0px,
    #8b5629 0.96017px -0.279415px 0px;
}
</style>